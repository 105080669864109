<template>
  <CRow>
    <CCol col="12" xl="12">

      <CButton
        variant="outline" 
        type="submit" 
        color="primary" 
        class='list-new-btn'
        @click="$router.push({path: '/manager/products/create'})"
        v-if="$store.getters.isAdmin"
        >
        <CIcon name="cil-check-circle" />New product
      </CButton> 

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Products</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    hover
                    sorter
                    striped
                    table-filter
                    :items="items"
                    :fields="fields"
                    :items-per-page="perPage"
                    :pagination="$options.paginationProps"
                    index-column
            >
              <template #location="{item, index}">
                <td class="py-2">
                  <p v-if="item.location">{{ item.location.name }}</p>
                </td>
              </template>
              <template #state="{item, index}">
                <td>
                  <CBadge :color="getBadge(item.state)">
                    {{ item.state }}
                  </CBadge>
                </td>
              </template>
              <template #actions="{item, index}">
                <td class="py-2">
                  <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="detailClicked(item, index)"
                  >
                    Details
                  </CButton>
                </td>
              </template>

            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>

import ProductAPI from '/app/src/api/product.js'

export default {
  name: 'Products',
  data: () => {
    return {
      productAPI: new ProductAPI(),
      items: [],
      fields: [
        { key: 'number' },
        { key: 'type' },
        { key: 'state' },
        { key: 'location_name' },
        { key: 'actions' }
      ],
      perPage: 20,
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  created: function () {
    this.getProducts()
  },
  methods: {
    getProducts: function() {
      this.$store.dispatch('loading')
      let self = this
      this.productAPI.all()
      .then((products) => {

        for(let i = 0; i < products.length; i++) {
          if ('location' in products[i]) {
            products[i].location_name = products[i].location.name
          }
        }

        this.$store.dispatch('stopLoading')
        
        self.items = products
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
      })
    },
    getBadge (state) {
      return state === 'correct' ? 'success'
            : state === 'broken' ? 'danger' : 'primary'
    },
    detailClicked (item, index) {
      const itemLink = `/manager/products/${item.id.toString()}`
      this.$router.push({path: itemLink})
    }
  }
}
</script>
